<template>
  <div class="repaymentDataSummarization">
    <div>
      <el-form class="flex"
        ref="searchRef"
        :model="searchForm"
        label-width="100px"
        size="small"
      >
        <el-row class="flex-1">
          <el-col :span="6">
            <el-form-item label="签约主体">
              <el-select
                style="width: 100%"
                v-model="searchForm.entityId"
                :remote-method="entityRemoteMethod"
                :loading="entityLoading"
                placeholder="请输入关键词"
                filterable
                remote
                clearable
                @change="initData"
                @clear='onEntityClear'
              >
                <el-option
                  v-for="item in entityList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="一级商家">
              <el-select
                style="width: 100%"
                v-model="searchForm.seniorId"
                :remote-method="seniorRemoteMethod"
                :loading="seniorLoading"
                placeholder="请输入关键词"
                clearable
                filterable
                remote
                @change="initData"
                @clear='onSeniorClear'
              >
                <el-option
                  v-for="item in seniorList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="二级商家">
              <el-select
                style="width: 100%"
                v-model="searchForm.businessId"
                :remote-method="businessRemoteMethod"
                :loading="businessLoading"
                placeholder="请输入关键词"
                filterable
                remote
                clearable
                @change="initData"
                @clear='onBusinessClear'
              >
                <el-option
                  v-for="item in businessList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期">
              <el-date-picker
                style="width: 100%"
                v-model="collect_date"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="setDateRange"
                @change="initData"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="ml_20">
          <el-button type="primary" size="small" @click="initData"
            >查询</el-button>
        </div>
      </el-form>
    </div>
    <div class="auth-table">
      <base-table
        :columns="columns"
        :loading="loading"
        :data="tableData">
      </base-table>
    </div>
  </div>
</template>

<script>
import { selectBusiness, selectEntity, selectSenior } from "@/api/selectfun.js";
import { repaymentSummaryDetail } from "@/api/dataSummarization.js";
export default {
  name: "repaymentDataSummarization",
  data() {
    return {
      tableData: [],
      searchForm: {
        seniorId: "",
        entityId: "",
        businessId: "",
        startTime: "",
        endTime: "",
      },
      loading:false,
      seniorList: [],
      seniorLoading: false,
      entityList: [],
      entityLoading: false,
      businessList: [],
      businessLoading: false,
      collect_date: [],
      setDateRange: {
        // disabledDate: (time) => {
        //   return time.getTime() > Date.now();
        // },
      },

      columns: [
        {
          label: "",
          prop: 'title',
          align: 'center',
          width: 80,
        },
        {
          label: "平台总得",
          prop: 'platform_total',
          align: 'center',
        },
        {
          label: "平台管理费",
          prop: 'platform_interest',
          align: 'center',
        },
        {
          label: "平台管理费(用户承担)",
          prop: 'platform_interest_u',
          align: 'center',
        },
        {
          label: "平台管理费(机构贴息)",
          prop: 'platform_interest_b',
          align: 'center',
        },
        {
          label: "商家总得",
          prop: 'business_total',
          align: 'center',
        },
        {
          label: "本金",
          prop: 'business_principal',
          align: 'center',
        },
        {
          label: "商家管理费",
          prop: 'business_interest',
          align: 'center',
        },
        {
          label: "逾期费用",
          prop: 'overdue_total',
          align: 'center',
        },
        {
          label: "逾期滞纳金",
          prop: 'overdue_fee',
          align: 'center',
        },
        {
          label: "逾期罚金",
          prop: 'overdue_fine',
          align: 'center',
        },
        {
          label: "提前还款手续费",
          prop: 'advance_p',
          align: 'center',
        },
        {
          label: "退款手续费(平台)",
          prop: 'refund_p',
          align: 'center',
        },
        {
          label: "退款手续费(商家)",
          prop: 'refund_b',
          align: 'center',
        },
      ]
    };
  },

  mounted() {
    this.setSelectTime();
    this.initData();
  },

  methods: {
    async initData() {
      if (this.collect_date && this.collect_date.length == 2) {
        this.searchForm.startTime = this.collect_date[0] + " " + "00:00:00";
        this.searchForm.endTime   = this.collect_date[1] + " " + "23:59:59";
      } else {
        this.searchForm.startTime = "";
        this.searchForm.endTime   = "";
      }
      
      this.loading = true
      const { code, data } = await repaymentSummaryDetail(this.searchForm);
      this.loading = false
      if (code == 200) {
        const info  = data[0]
        const deserved = {
          title:                "应得",
          platform_total:       info.platform_deserved,  // 平台总应得
          platform_interest:    info.platform_deserved_interest,  // 平台管理费
          platform_interest_u:  info.deserved_interest_user_payer,  // 平台管理费(用户承担)
          platform_interest_b:  info.deserved_interest_business_payer,  // 平台管理费(商家承担)
          business_total:       info.business_deserved,  // 商家总应得
          business_principal:   info.deserved_principal,  // 应还本金
          business_interest:    info.business_deserved_interest,  // 商家管理费
          overdue_total:        info.deserved_overdue,  // 逾期费用
          overdue_fee:          info.deserved_overdue_late_fee,  // 滞纳金
          overdue_fine:         info.deserved_overdue_fine,  // 罚金
          advance_p:            "——",  // 提前还款手续费
          refund_p:             "——",  // 退费手续费(平台)
          refund_b:             "——",  // 退费手续费(商家)
        }
        const real = {
          title:                "实得",
          platform_total:       info.platform_real_income,  // 平台总实得
          platform_interest:    info.platform_interest_real_income,  // 平台管理费
          platform_interest_u:  info.platform_interest_user_payer,  // 平台管理费(用户承担)
          platform_interest_b:  info.platform_interest_business_payer,  // 平台管理费(商家承担)
          business_total:       info.business_real_income,  // 商家总实得
          business_principal:   info.business_principal_real_income,  // 实还本金
          business_interest:    info.business_interest_real_income,  // 商家管理费
          overdue_total:        info.overdue_real_income,  // 逾期费用
          overdue_fee:          info.overdue_late_fee_real_income,  // 滞纳金
          overdue_fine:         info.overdue_fine_real_income,  // 罚金
          advance_p:            info.platform_advance_income,  // 提前还款手续费
          refund_p:             info.refund_platform_income,  // 退费手续费(平台)
          refund_b:             info.refund_business_income,  // 退费手续费(商家)
        }

        this.tableData = [
          deserved, real
        ]
      }
    },
    businessRemoteMethod(query){
      if(query != ''){
        this.businessLoading = true
        setTimeout(()=>{
          this.businessLoading = false
          this.getSelectBusiness(query)
        },200)
      } else {
        this.businessList = [];
      }
    },
    onBusinessClear(){
      this.searchForm.businessId = ''
      this.businessList = [];
    },
    async getSelectBusiness(query) {
      const param = {
        business_name : query
      }
      const { code, data } = await selectBusiness(param);
      if (code == 200) {
        this.businessList = data.map((item) => {
          item.value = item.business_id;
          item.label = item.full_name;
          return item;
        });
      }
    },
    entityRemoteMethod(query) {
      if (query != "") {
        this.entityLoading = true;
        setTimeout(() => {
          this.entityLoading = false;
          this.getSelectEntity(query);
        }, 200);
      } else {
        this.entityList = [];
      }
    },
    onEntityClear(){
      this.searchForm.entityId = ''
      this.entityList = [];
    },
    async getSelectEntity(query) {
      const param = {
        entity_name:query
      }
      const { code, data } = await selectEntity(param);
      if (code == 200) {
        this.entityList = data.map((item) => {
          item.value = item.entity_id;
          item.label = item.entity_name;
          return item;
        });
      }
    },
    seniorRemoteMethod(query) {
      if (query != "") {
        this.seniorLoading = true;
        setTimeout(() => {
          this.seniorLoading = false;
          this.getSelectSenior(query);
        }, 200);
      } else {
        this.seniorList = [];
      }
    },
    onSeniorClear(){
      this.searchForm.seniorId = ''
      this.seniorList = [];
    },
    async getSelectSenior(query) {
      const param = {
        senior_name:query
      }
      const { code, data } = await selectSenior(param);
      if (code == 200) {
        this.seniorList = data.map((item) => {
          item.value = item.senior_id;
          item.label = item.senior_name;
          return item;
        });
      }
    },
    setSelectTime() {
      var date = new Date();
      var y = date.getFullYear();
      var m =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

      var start_day = y + "-" + m + "-" + "01";
      var end_day = `${y}-${m}-${d}`;

      this.collect_date = [start_day, end_day];
    },
  },
};
</script>

<style lang="scss" scoped>
.repaymentDataSummarization {
  .auth-table {
    /deep/ .table_header {
      background-color: #f8f8f9 !important;
      color: #515a6e !important;
    }
  }
}
</style>